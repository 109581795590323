<template>
  <div class="px-8 px-md-12 py-8">
    <v-row color="white" class="d-flex">
      <v-col>
        <v-row>
          <v-col>
            <v-row>
              <router-link :to="{ name: 'competition-matches-filter'}">
                <v-icon color="primary" size="16">arrow_back</v-icon>
                {{ $t('project.competition.navigateBack') }}
              </router-link>
            </v-row>
            <v-row class="d-flex flex-column flex-sm-row justify-space-between mb-6 mt-12">
              <div><v-icon color="primary" size="24" class="mr-3"> view_quilt</v-icon><span v-text="titleText" /></div>
              <div v-if="selectedStageId === 'rrobin'">
                <v-icon color="primary" size="24" class="mr-3"> group</v-icon>
                <span>{{ rrobinInfo }}</span>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="tabs.length">
          <v-tabs v-model="tab" color="secondary" show-arrows>
            <v-tabs-slider color="primary" />
            <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
          </v-tabs>
          <v-divider style="border-right: 1px solid;" class="mb-4" />
        </v-row>
        <v-row class="match-filter">
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              v-if="cohortId && selectGroupItems.length > 1"
              v-model="selectedGroupId"
              :items="selectGroupItems"
              :label="$t('project.sections.groups')"
              append-icon="mdi-chevron-down" clearable
              class="mr-sm-6 match-filter__select-field"
            />
            <v-select
              v-if="clubId && selectTeamItems.length > 1"
              v-model="selectedTeamId"
              :items="selectTeamItems"
              :label="$t('common.teams')"
              append-icon="mdi-chevron-down" clearable
              class="mr-sm-6 match-filter__select-field"
            />
            <v-select
              v-if="locationId && selectFieldItems.length > 1"
              v-model="selectedFieldId"
              :items="selectFieldItems"
              :label="$t('common.fields')"
              append-icon="mdi-chevron-down" clearable
              class="mr-sm-6 match-filter__select-field"
            />
            <v-select
              v-model="selectedStatus"
              class="match-filter__select-field w-100"
              :items="selectStatusItems"
              :label="$t('common.status')"
              append-icon="mdi-chevron-down" clearable
            >
              <template #item="{ item }">
                <span class="status-icon mr-4" :style="{ 'background-color': item.color }" />
                <span v-text="item.text" />
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex flex-row align-center justify-end">
            <v-text-field
              v-model="search" :label="$t('common.search')"
              class="match-filter__search" outlined
              hide-details dense append-icon="search"
            />
          </v-col>
        </v-row>
        <v-row v-if="groupedMatches.some(g => g.items.length)" class="mt-2">
          <v-col cols="12" class="pa-0">
            <match-group-list
              v-for="group in groupedMatches" :key="group.name"
              :organization-id="organizationId" :project-id="projectId"
              :cohort-id="cohortId" :club-id="clubId" :location-id="locationId"
              :match-group="group" :search="search"
              :show-category="!!locationId" :show-location="!locationId"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import matchesAndRoundsMixin from '@/modules/competition/matches/matchesAndRoundsMixin'
import i18n from '@/plugins/i18n'
import matchStatuses from '@/modules/competition/matches/matchStatuses'

export default {
  name: 'MatchList',
  components: {
    MatchGroupList: () => import('@/modules/competition/matches/MatchGroupList'),
  },
  mixins: [matchesAndRoundsMixin],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    cohortId: { type: String, default: null },
    clubId: { type: String, default: null },
    locationId: { type: String, default: null },
  },
  data() {
    return {
      tab: 0,
      search: '',
      selectedGroupId: null,
      selectedTeamId: null,
      selectedFieldId: null,
      selectedStatus: null,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters('competition', ['hasRrobinSubgroups', 'isRegularNba', 'matchLocations', 'groups', 'clubs', 'teams', 'cohorts']),
    // By cohort
    cohort: ({ cohorts, cohortId }) => cohorts.find(cohort => cohort.id === cohortId),
    currentGroups: ({ groups, cohortId, selectedStageId }) => groups.filter(group => group.cohortId === cohortId && group.stageId === selectedStageId),
    selectGroupItems: ({ currentGroups }) => currentGroups.map(g => ({ text: g.name, value: g.id })),
    selectedStageId: ({ tab, cohortId }) => cohortId ? (tab === 0 ? 'rrobin' : 'elimination') : null,
    rrobinInfo: ({ cohort }) => i18n.t('project.competition.groupsCount', {
      groupCount: cohort?.dataByStageId?.rrobin?.groupCount,
      teamsPerGroup: cohort?.dataByStageId?.rrobin?.teamsPerGroup,
    }),
    // By club
    club: ({ clubs, clubId }) => clubs.find(club => club.id === clubId),
    currentTeams: ({ teams, clubId }) => teams.filter(team => team.clubId === clubId),
    selectTeamItems: ({ currentTeams }) => currentTeams.map(t => ({ text: t.nameWithCohort, value: `${t.cohortId}${t.id}` })),
    // By location
    location: ({ matchLocations, locationId }) => matchLocations.find(location => location.id === locationId),
    currentFields: ({ location }) => location?.fields ?? [],
    selectFieldItems: ({ currentFields }) => currentFields.map(f => ({ text: f.name, value: f.id })),
    // General
    titleText: ({ cohort, club, location }) => cohort?.name ?? club?.name ?? location?.name,
    tabs: ({ cohortId, locationId, clubId, rounds, isRegularNba }) => {
      const cohortTabs = [
        { id: 'rrobin', name: isRegularNba ? i18n.t('project.competition.stages.regularSeason') : i18n.t('project.competition.stages.rrobin') },
        ...rounds.filter(r => r.cohortId === cohortId && r.stageId === 'elimination')
          .filter((v, i, a) => a.findIndex(v2 => (v2.index === v.index)) === i).reverse().map(r => ({ id: r.index.toString(), name: r.name })),
      ]
      if (cohortId) return cohortTabs
      if (clubId) return []
      if (locationId) return []
    },
    prefilteredMatches: ({ cohortId, clubId, locationId, matches, currentTeams }) => {
      if (cohortId) return matches.filter(match => match.cohortId === cohortId)
      if (clubId) return matches.filter(match => currentTeams.some(team => team.cohortId === match.cohortId && Object.keys(match.teamsById).includes(team.id)))
      if (locationId) return matches.filter(match => match.locationId === locationId)
    },
    filteredMatches: ({ prefilteredMatches, selectedStatus, selectedGroupId, selectedTeamId, selectedFieldId }) => prefilteredMatches
      .filter(match => selectedStatus ? match.status === selectedStatus : true)
      .filter(match => selectedGroupId ? match.groupId === selectedGroupId : true)
      .filter(match => selectedTeamId ? Object.keys(match.teamsById).map(teamId => `${match.cohortId}${teamId}`).includes(selectedTeamId) : true)
      .filter(match => selectedFieldId ? match.fieldId === selectedFieldId : true),
    groupedMatches: ({ filteredMatches, cohortId, clubId, locationId, currentGroups, currentTeams, currentFields, tabs, tab }) => {
      const filterByGroup = group => filteredMatches.filter(match => match.groupId === group.id)
        .filter(match => match.stageId === 'elimination' ? match.roundId.split('-')[1] === tabs[tab].id : true)
        .map((match, index, arr) => match?.roundId !== arr[index + 1]?.roundId ? ({ ...match, lastOfRound: true }) : match)
      const filterByTeam = team => filteredMatches.filter(match => team.cohortId === match.cohortId && Object.keys(match.teamsById).includes(team.id))
      const filterByField = field => filteredMatches.filter(match => match.fieldId === field.id)
      if (cohortId) return currentGroups.map(group => ({ name: group.name, items: filterByGroup(group), hideName: currentGroups.length === 1 }))
      if (clubId) return currentTeams.map(team => ({ name: team.nameWithCohort, items: filterByTeam(team) }))
      if (locationId) return currentFields.map(field => ({ name: field.name, items: filterByField(field) }))
    },
    selectStatusItems: ({ prefilteredMatches }) => matchStatuses.filter(s => prefilteredMatches.some(m => m.status === s.id))
      .map(s => ({ text: i18n.t(`project.competition.match.${s.id}`), color: matchStatuses.find(s2 => s2.id === s.id).color, value: s.id })),
  },
  watch: {
    selectedStageId() { this.selectedGroupId = null },
  },
}
</script>

<style lang="scss">
.custom.v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none;
}

.custom.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}

.match-filter {
  &__search {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 15rem;
    }
  }
  &__select-field {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      width: 33%;
    }
  }
}

.status-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    filter: blur(2px);
  }
</style>
