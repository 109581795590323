import vuetify from '@/plugins/vuetify'

const { light: theme } = vuetify.preset.theme.themes
export default [
  { id: 'notScheduled', index: 0, color: theme.warning },
  { id: 'notStarted', index: 1, color: theme.grey50 },
  { id: 'running', index: 2, color: theme.accent },
  { id: 'notSigned', index: 3, color: theme.error },
  { id: 'finished', index: 4, color: theme.success },
]
